import React from 'react';
import type { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { TestAccount } from '../api';
import { GeneratePresetButton } from '../components/GeneratePreset';
import { ShowPresetButton } from '../components/ShowPreset';
import { UploadPresetButton } from '../components/UploadPreset';

export const tableColumns = (
  setGenerateResult: React.Dispatch<React.SetStateAction<TestAccount | null>>
): MUIDataTableColumn[] => [
  { name: 'preset_id', label: 'Preset ID' },
  {
    name: 'description',
    label: 'Description',
    options: {},
  },
  {
    name: 'Create',
    label: 'Generate Account',
    options: {
      searchable: false,
      sort: false,
      customBodyRender: (_: any, tableMeta: any) => {
        const rowData = tableMeta.rowData;
        const presetId = rowData[0];
        return (
          <GeneratePresetButton
            presetId={presetId}
            setGenerateResult={setGenerateResult}
          />
        );
      },
    },
  },
  {
    name: 'Show Preset File',
    label: 'Show Preset File',
    options: {
      searchable: false,
      sort: false,
      customBodyRender: (_: any, tableMeta: any) => {
        const rowData = tableMeta.rowData;
        const presetId = rowData[0];
        return <ShowPresetButton presetId={presetId} />;
      },
    },
  },
  {
    name: 'Upload Preset File',
    label: 'Upload Preset File',
    options: {
      searchable: false,
      sort: false,
      customBodyRender: (_: any, tableMeta: any) => {
        const rowData = tableMeta.rowData;
        const presetId = rowData[0];
        return <UploadPresetButton presetId={presetId} />;
      },
    },
  },
];

export const options: MUIDataTableOptions = {
  responsive: 'standard',
  selectableRows: 'none',
  print: false,
  download: false,
  viewColumns: false,
  elevation: 0,
  sortOrder: {
    name: 'preset_id',
    direction: 'asc',
  },
  rowsPerPage: 10,
};
