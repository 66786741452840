import { toCamelCase } from 'js-convert-case';
import type { MeteredEntitlement } from '../models/service-alp.model';

function getUsage(entitlements: MeteredEntitlement[], quotaName: string) {
  const currentEntitlement = entitlements.find(
    (entitlement) => toCamelCase(entitlement.entitlementKey) === quotaName
  );

  return currentEntitlement?.currentUsage ?? null;
}

type SiteAllowance = [quotaName: string, usage: number];

export const mapAllowanceMetering = (
  siteAllowance: SiteAllowance,
  entitlements: MeteredEntitlement[]
) => {
  const [key, value] = siteAllowance;

  return [
    key,
    {
      quota: value,
      usage: getUsage(entitlements, key),
    },
  ];
};
