import { UseQueryResult } from '@tanstack/react-query';
import { apiGet, apiPost } from '../../utils/api';
import { useQuery } from '@tanstack/react-query';
import type { CamelCasedProperties } from 'type-fest';

export type TestAccount = {
  email: string;
  password: string;
};

type TestAccountPresetDto = {
  preset_id: string;
  description: string;
};

export type TestAccountPreset = CamelCasedProperties<TestAccountPresetDto>;

const defaultTestAccountsQueryKey = ['useGetTestAccounts'];

export const useGetTestAccountPresets = (): UseQueryResult<
  TestAccountPreset[],
  Error
> => {
  return useQuery({
    queryKey: defaultTestAccountsQueryKey,
    queryFn: async () => {
      const data = await apiGet('/testing/presets');
      return data;
    },
  });
};

export const generateTestAccount = async (preset_id: string) => {
  const response = await apiPost(`/testing/presets/${preset_id}`);
  return response;
};
