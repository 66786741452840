import * as Cookies from 'js-cookie';
import { apiPost } from '../../utils/api';

const LOGOUT_URL = '/logout';
export function postLogout() {
  return apiPost(LOGOUT_URL, null, {}, 2, {}, true, 'logout success')
    .then((res) => {
      if (res.success) {
        return Promise.resolve();
      } else {
        return Promise.reject({
          message: 'Got an unexpected HTTP response code: ' + res.status,
        });
      }
    })
    .finally(() => {
      Cookies.remove('LOGGED-IN');
      Cookies.remove('session');
    });
}
