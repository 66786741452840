import React, { useState } from 'react';
import { Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import { TestAccountPreset } from '../api';

type ButtonIconProps = {
  uploading: boolean;
};

const ButtonIcon = ({ uploading }: ButtonIconProps): JSX.Element => {
  if (uploading) {
    return <CircularProgress title="Uploading preset" size="1rem" />;
  }

  return <CloudUploadIcon />;
};

export const UploadPresetButton = ({
  presetId,
}: Pick<TestAccountPreset, 'presetId'>) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const handleClick = async () => {
    setUploading(true);
    setUploading(false);
  };

  return (
    <Button
      variant="contained"
      aria-label="Upload"
      aria-busy={uploading}
      title="Upload"
      disabled={uploading}
      startIcon={<ButtonIcon uploading={uploading} />}
      onClick={handleClick}
    >
      Upload
    </Button>
  );
};
