import React from 'react';

interface RestrictFromEnvironmentProps {
  children: React.ReactNode;
  env?: string;
}

const checkEnvironment = (env: string): boolean => {
  return env !== process.env.REACT_APP_ENVIRONMENT;
};

const RestrictFromEnvironment: React.FC<RestrictFromEnvironmentProps> = ({
  children,
  env = '',
}) => {
  const isAllowed = checkEnvironment(env);
  return isAllowed ? <>{children}</> : null;
};

export default RestrictFromEnvironment;
