import { Paper, Box, Typography, Divider, Tooltip } from '@mui/material';
import styles from '../containers/ProvisionTestAccount.module.css';
import { TestAccount } from '../api';

export const GeneratePresetResult = ({ email, password }: TestAccount) => {
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    alert(`${text} copied to clipboard!`);
  };

  return (
    <Paper className={styles.container} elevation={1}>
      <Box
        sx={{
          backgroundColor: '#f9f9f9',
          border: '1px solid #ddd',
          borderRadius: '8px',
          padding: '16px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: '8px', color: '#333' }}>
          Account Generated
        </Typography>
        <Divider sx={{ marginBottom: '8px' }} />
        <Typography variant="h6">Email</Typography>
        <Tooltip title="Click to copy" placement="top">
          <Typography
            variant="body1"
            sx={{ cursor: 'pointer', marginLeft: '10px' }}
            onClick={() => handleCopy(email)}
          >
            {email}
          </Typography>
        </Tooltip>
        <Typography variant="h6">Password</Typography>
        <Typography
          variant="body1"
          sx={{ cursor: 'pointer', marginLeft: '10px' }}
        >
          {password}
        </Typography>
      </Box>
    </Paper>
  );
};
