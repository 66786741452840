import React, { useState } from 'react';
import { Button } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CircularProgress from '@mui/material/CircularProgress';
import { TestAccountPreset, generateTestAccount, TestAccount } from '../api';
import { useToasts } from 'react-toast-notifications';

type ButtonIconProps = {
  generating: boolean;
};

const ButtonIcon = ({ generating }: ButtonIconProps): JSX.Element => {
  if (generating) {
    return <CircularProgress title="Generating account" size="1rem" />;
  }

  return <CloudDownloadIcon />;
};

export const GeneratePresetButton = ({
  presetId,
  setGenerateResult,
}: Pick<TestAccountPreset, 'presetId'> & {
  setGenerateResult: React.Dispatch<React.SetStateAction<TestAccount | null>>;
}) => {
  const { addToast } = useToasts();
  const [generating, setGenerating] = useState<boolean>(false);
  const handleClick = async () => {
    setGenerating(true);
    setGenerateResult(null);
    try {
      const data: TestAccount = await generateTestAccount(presetId);
      setGenerateResult(data);
      addToast(
        `Account generated. Email: ${data.email}, password: ${data.password}`,
        { appearance: 'success' }
      );
    } catch (error) {
      setGenerateResult(null);
      console.error(error);
    }
    setGenerating(false);
  };

  return (
    <Button
      variant="contained"
      aria-label="Generate"
      aria-busy={generating}
      title="Generate"
      disabled={generating}
      startIcon={<ButtonIcon generating={generating} />}
      onClick={handleClick}
    >
      Generate
    </Button>
  );
};
