import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useToasts } from 'react-toast-notifications';
import { postLogout } from '../containers/Login/api';
import { isAuthenticated } from '../utils/auth';

const Logout = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const requestAndRedirect = () => {
    return postLogout()
      .then(() => {})
      .catch((error) => {
        addToast(`An error occurred while logging out: ${error?.message}`, {
          appearance: 'error',
        });
      })
      .finally(() => {
        history.push('/login');
      });
  };

  if (isAuthenticated()) {
    return <Button onClick={requestAndRedirect}>Logout</Button>;
  }
  return null;
};

export default Logout;
