import { useState } from 'react';
import { Paper } from '@mui/material';
import ShowSpinner from '../../../components/ShowSpinner';
import styles from './ProvisionTestAccount.module.css';
import { useGetTestAccountPresets, TestAccount } from '../api';
import MUIDataTable from 'mui-datatables';
import {
  tableColumns,
  options,
} from '../utils/ProvisionTestAccountDataTableOptions';
import { GeneratePresetResult } from '../components/GeneratePresetResult';

export const ProvisionTestAccount = () => {
  const [generateResult, setGenerateResult] = useState<TestAccount | null>(
    null
  );
  const {
    data: testAccounts = null,
    error,
    isError,
    isLoading,
  } = useGetTestAccountPresets();
  if (isError) {
    return (
      <p className="error">{`Error fetching default features: ${error}`}</p>
    );
  }

  if (isLoading === null || testAccounts === null) {
    return <ShowSpinner />;
  }
  const columns = tableColumns(setGenerateResult);

  return (
    <div>
      <Paper className={styles.container} elevation={1}>
        {!isLoading ? (
          <div>
            <MUIDataTable
              title={'Test Accounts'}
              data={testAccounts}
              columns={columns}
              options={options}
            />
          </div>
        ) : (
          <ShowSpinner />
        )}
      </Paper>
      {generateResult ? (
        <GeneratePresetResult
          email={generateResult.email}
          password={generateResult.password}
        />
      ) : null}
    </div>
  );
};
