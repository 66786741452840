import React, { useState } from 'react';
import { Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircularProgress from '@mui/material/CircularProgress';
import { TestAccountPreset } from '../api';

type ButtonIconProps = {
  showing: boolean;
};

const ButtonIcon = ({ showing }: ButtonIconProps): JSX.Element => {
  if (showing) {
    return <CircularProgress title="Showing preset" size="1rem" />;
  }

  return <VisibilityIcon />;
};

export const ShowPresetButton = ({
  presetId,
}: Pick<TestAccountPreset, 'presetId'>) => {
  const [showing, setShowing] = useState<boolean>(false);
  const handleClick = async () => {
    setShowing(true);
    setShowing(false);
  };

  return (
    <Button
      variant="contained"
      aria-label="Show"
      aria-busy={showing}
      title="Show"
      disabled={showing}
      startIcon={<ButtonIcon showing={showing} />}
      onClick={handleClick}
    >
      Show
    </Button>
  );
};
